import Noty from 'noty'

const notifications = window.notifications = {

    formatMessage: function(msg) {
        /**
         * @param {String, Array, object} msg
         */
        if (typeof msg === "string") {
            return msg;
        } else if (typeof msg === "object" && Array.isArray(msg)) {
            return msg.join("\n");
        } else if (typeof msg === "object") {
            let new_msg = "";
            Object.keys(msg).map(function (key, value) {
                new_msg += key + ": " + value + "\n";
            });
            return new_msg;
        }
    },

    /**
     * Function that shows a notification
     * @param {String} text
     * @param {String} type (warning, error, success, info)
     * @param {boolean, int} timeout
     */
    showNoty: function(text, type, timeout) {
        let noty_options = {
            type: type,
            layout: "topRight",
            theme: "sunset",
            text: text,
            visibilityControl: true,
            closeWith: ['click'],
            timeout: timeout,
        };
        // new Noty(noty_options).show();
        if (window.Toast) {
            window.Toast.addNotification({text, type, timeout})
        } else {
            window.ToastCache = window.ToastCache || []
            window.ToastCache.push({text, type, timeout})
        }
    },

    showSuccessNoty: function(msg, timeout) {
        msg = this.formatMessage(msg);
        timeout = (typeof timeout !== "undefined") ? timeout : 3000;    // 3s
        this.showNoty(msg, "success", timeout);
    },

    showInfoNoty: function(msg, timeout) {
        msg = this.formatMessage(msg);
        timeout = (typeof timeout !== "undefined") ? timeout : 3000;    // 3s
        this.showNoty(msg, "info", timeout);
    },

    showWarningNoty: function(msg, timeout) {
        msg = this.formatMessage(msg);
        timeout = (typeof timeout !== "undefined") ? timeout : 10000;   // 10s
        this.showNoty(msg, "warning", timeout);
    },

    showErrorNoty: function(msg, timeout) {
        msg = this.formatMessage(msg);
        timeout = (typeof timeout !== "undefined") ? timeout : 60000;   // 1m
        this.showNoty(msg, "error", timeout);
    },

    showUnexpectedErrors: function(errors) {
        if (errors.hasOwnProperty("error")) {
            this.showErrorNoty(errors["error"]);
        }
    },

    blockScreen: function() { // msg
        const cover = document.getElementById('loader-cover')
        cover.style.display = 'flex'
        /*
        msg = (typeof msg !== "undefined") ? msg : "Espere mientras se terminan de realizar los cambios";
        let options = {
            theme: "sk-cube-grid",
            message: msg
        };
        HoldOn.open(options);
        */
    },

    unblockScreen: function() {
        const cover = document.getElementById('loader-cover')
        cover.style.display = 'none'
        /*
        HoldOn.close();
        */
    },

};

export default notifications
