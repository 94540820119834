'use strict'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

const opts = {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#009EFF',
        secondary: '#0478B2',
        accent: '#00F3FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  },
  iconfont: 'fa',
  lang: {
    locales: { es },
    current: 'es'
    // t: (key, ...params) => vuei18n.t(key, params) // vue-i18n connector
  }
}

export default new Vuetify(opts)

/*
old:

Vue.use(Vuetify, {
  lang: {
    locales: {
      es: {
        dataIterator: {
          rowsPerPageText: 'Elementos por página:',
          rowsPerPageAll: 'Todos',
          pageText: '{0}-{1} de {2}',
          noResultsText: 'No se han encontrado resultados',
          nextPage: 'Página siguiente',
          prevPage: 'Página anterior'
        },
        dataTable: {
          rowsPerPageText: 'Filas por página:'
        },
        noDataText: 'No hay datos'
      }
    },
    current: 'es'
  }
})

*/
