'use strict'

import Axios from 'axios'
import moment from 'moment'
import loggerFactory from '@/modules/logger'

const log = loggerFactory('JWT', true, false)

const loginAxios = Axios.create({
  // baseURL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
})

const TXWAPI = Axios.create({
  // baseURL: baseURL + 'api/',
  withCredentials: true,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/vnd.api+json',
    Accept: 'application/vnd.api+json'
  }
})

const session = {
  uid: undefined,
  token: undefined,
  refresh: undefined,
  expiration: undefined,
  username: undefined,
  superuser: undefined,
  tenant: undefined
}

const timeToExpire = function (expiration) {
  return expiration - Math.floor(Date.now() / 1000) - 30
}

const tokenExpired = function () {
  return !session.token || timeToExpire(session.expiration) <= 0
}

const getCompanies = function (search) {
  const params = { include: 'contables,titulares', search }
  return TXWAPI.get(window.loadBaseURL + 'api/companies', { params })
}

const setCompany = function (company) {
  // log('Set company...', company)
  return loginAxios.get(window.loadBaseURL + 'apitenant/?tenant=' + company).then(function (response) {
    // log('Tenant setted', response.data)
    session.tenant = response.data.tenant
    // saveSession()
    return session
  }).catch(function (error) {
    if (error.response) {
      throw error.response.data ? error.response.data.error : error
    } else {
      throw error
    }
  })
}

const setAccountant = function (company) {
  // log('Set accountant...', company)
  return TXWAPI.post(window.loadBaseURL + 'api/companies/' + company.id + '/accountants/', null,
    { params: { user_id: session.uid, include: 'contables,titulares' } })
}

const removeAccountant = function (company) {
  // log('Remove accountant...', company)
  return TXWAPI.delete(window.loadBaseURL + 'api/companies/' + company.id + '/accountants/', { params: { user_id: session.uid, include: 'contables,titulares' } })
}

let $refreshTimeout
const updateRefreshTask = function () {
  const secondsToRefresh = timeToExpire(session.expiration)
  log.info('Refresh task scheduled ' + moment().add(secondsToRefresh, 'seconds').fromNow())
  $refreshTimeout && clearTimeout($refreshTimeout)
  $refreshTimeout = setTimeout(function () {
    login(true)
  }, secondsToRefresh * 1000)
}

const logout = function (to) {
  return loginAxios.post(window.loadBaseURL + 'apilogout/').then(function (response) {
    clearSession()
    if (to) {
      window.location.assign(to)
    } else {
      location.reload()
    }
  })
}

const login = function (username, password, tenant) {
  recoverySession()
  return new Promise((resolve, reject) => {
    const data = {}
    if (username && password) {
      log.info('New login')
      Object.assign(data, {
        grant_type: 'password',
        username,
        password
      })
    } else if (username === true) {
      log.info('Login checking current session...')
      if (tokenExpired()) {
        log.warn('Login EXPIRED try to RECOVERY')
        clearSession()
        Object.assign(data, {
          grant_type: 'recovery'
        })
      } else {
        log('Login try to REFRESH')
        Object.assign(data, {
          grant_type: 'refresh_token',
          refresh_token: session.refresh
        })
        clearSession()
      }
    } else {
      login(true).then(function () {
        log.success('Login session RECOVERED')
        resolve()
      }).catch(function (error) {
        log.error('Login try to RECOVERY fails')
        clearSession()
        reject(error)
      })
      return
    }

    const url = tenant ? 'apilogin/?tenant=' + tenant : 'apilogin/'
    loginAxios.post(window.loadBaseURL + url, data).then(function (response) {
      session.token = response.data.access_token
      session.refresh = response.data.refresh_token
      session.expiration = response.data.exp
      session.uid = response.data.uid
      session.username = response.data.username
      session.superuser = response.data.superuser
      response.data.tenant && (session.tenant = response.data.tenant)
      log.success('Login SUCCESS', response)
      // saveSession()
      updateRefreshTask()
      resolve()
    }).catch(function (error) {
      clearSession()
      reject(error)
    })
  })
}

/*
const saveSession = function () {
  if (session.token) {
    const sessionDecoded = {
      token: session.token,
      refresh: session.refresh,
      expiration: session.expiration,
      tenant: session.tenant,
      uid: session.uid,
      username: session.username
    }
    sessionDecoded.tenant && (sessionDecoded.tenant = session.tenant)
    const sessionEncoded = btoa(JSON.stringify(sessionDecoded))
    localStorage.setItem('txsession', sessionEncoded)
    return true
  } else {
    return false
  }
}
*/

const recoverySession = function () {
  const sessionEncoded = localStorage.getItem('txsession')
  if (sessionEncoded) {
    const sessionDecoded = JSON.parse(atob(sessionEncoded))
    Object.assign(session, sessionDecoded)
    return true
  } else {
    return false
  }
}

const clearSession = function () {
  localStorage.removeItem('txsession')
  delete session.token
  delete session.refresh
  delete session.expiration
  delete session.tenant
  delete session.uid
  delete session.username
}

// EXPORT:

const AUTH = window.AUTH = {
  login,
  logout,
  session,
  updateRefreshTask,
  tokenExpired,
  timeToExpire,
  getCompanies,
  setCompany,
  setAccountant,
  removeAccountant,
  // saveSession,
  recoverySession,
  clearSession
}

export default AUTH
