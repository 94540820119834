'use strict'
import notifications from 'notifications'
import JWT from '@/modules/JWT'
import Vue from 'vue'
import Axios from 'axios'
import vuetify from '@/vue/modules/vuetify'
import { vuei18n } from '@/vue/modules/i18n'
import Login from '@/vue/views/Login.vue'
import 'styles'
import TXF from '../package.json'
import loggerFactory from '@/modules/logger'
import '@/assets/css/overrideKO.scss'
const log = loggerFactory('Main', true)

window.JWT = JWT
window.txf = { name: TXF.name, description: TXF.description, version: TXF.version }
log.info('Init', window.txf)
log.time('main')

const currentQuery = new URLSearchParams(window.location.search)
let queryEnviroment = false
if (currentQuery.has('enviroment')) {
  const enviromentName = currentQuery.get('enviroment')
  if (enviromentName === 'test') {
    queryEnviroment = 'https://txerpaweb.test.aws.txerpa.com/'
  } else if (enviromentName === 'stage') {
    queryEnviroment = 'https://txerpaweb.stage.aws.txerpa.com/'
  } else if (enviromentName === 'production') {
    queryEnviroment = 'https://app.txerpa.com/'
  }
}

const serveMode = queryEnviroment || 'http://app.txerpa.deve:8000/'
const prodMode = '/'
window.loadBaseURL = document.location.port === '8080' ? serveMode : prodMode
const contextLocation = window.loadBaseURL + 'oficina/facturacion/context/'

// Front Enviroment vars
window.addEventListener('load', event => {
  document.getElementById('loader-cover-view').style.display = 'block'

  const loadLogin = function () {
    window.vueLoginInstance = new Vue({
      vuetify,
      i18n: vuei18n,
      render: h => h(Login)
    }).$mount('#loginapp')

    notifications.unblockScreen()
  }

  const envLoader = window.envLoader = function () {
    const loginapp = document.getElementById('loginapp')
    loginapp && loginapp.remove()

    notifications.blockScreen()

    const context = Axios.create({
      baseURL: contextLocation,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json'
      }
    })

    context.get().then(function (response) {
      log.info('Context env loaded', response.data)
      window.app = response.data

      const currentQuery = new URLSearchParams(window.location.search)
      if (currentQuery.has('asuser') && currentQuery.get('asuser') === 'true') {
        log.info('Accountant: view as user', window.txf)
        window.app.asUser = true
        window.app.is_accountant = false
        window.app.is_superuser = false
      }

      JWT.session.tenant = window.app.tenant
      import('app.js')
    }, function (error) {
      if (error.response && error.response.data && error.response.data.redirect_url) {
        location.href = error.response.data.redirect_url
      } else {
        log.error('Context env loading error:', error)
        throw error
      }
    })
  }

  JWT.login()
    .then(function () {
      if (JWT.session.tenant) {
        log('Login session recovered, load enviroment...')
        envLoader()
      } else {
        log('No tenant for session, load login')
        loadLogin()
      }
    })
    .catch(function () {
      log('No session avaliable, load login')
      loadLogin()
    })
})
