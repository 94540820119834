<template>
  <v-card>
    <v-card-title>
      Seleccionar empresa
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="searchText"
        :loading="loading"
        :label="$t('generic.actions.search')"
        prepend-icon="search"
        hide-details
        single-line
        text
        :autofocus="true"
        :autocomplete="false"
        @input="search"
      />

      <v-alert v-if="error" type="error">{{ error }}</v-alert>

      <v-container fluid class="pt-10">
        <template v-for="item in results">
          <v-row :key="item.id" style="border-bottom: 1px solid #CCC" class="pa-2">
            <v-col cols="12" small="12" md="8">
              <v-icon>mdi-account-group</v-icon> <span class="subtitle-1 black--text">{{ item.nombre }} ({{ item.slug }})</span>
              <div class="pl-3">
                <div v-for="usuario in item.titulares.data" :key="'t' + usuario.id">
                  <v-icon x-small>mdi-account</v-icon> {{ usuario.full_name }} ({{ usuario.email }})
                </div>
                <div v-for="usuario in item.contables.data" :key="'c' + usuario.id">
                  <v-icon x-small>fa-user-secret</v-icon> {{ usuario.full_name }}
                </div>
                <div class="mt-2">
                  <v-alert v-for="message in item.messages" :key="message" type="warning" text dense>{{ message }}</v-alert>
                </div>
              </div>
            </v-col>
            <v-col cols="12" small="12" md="4" align="end">
              <v-btn v-if="item.accesible" x-small class="ma-1" @click="setCompany(item)">
                Entrar
              </v-btn>
              <v-btn v-if="item.canAssignAccountant" x-small class="ma-1" @click="setAccountant(item)">
                Agregarme como asesor
              </v-btn>
              <v-btn v-if="item.accesible && item.canRemoveAccountant" x-small class="ma-1" @click="removeAccountant(item)">
                Quitarme como asesor
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-container>

    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="cancelable"
        :loading="loading"
        color= "secondary"
        dark
        tile
        class="ma-1"
        @click="cancel"
      >Cancelar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from 'vue'
import JWT from '@/modules/JWT'
import loggerFactory from '@/modules/logger'

const log = loggerFactory('CompanySelector', true)

function includeUsers (company, users, includes, accountant) {
  for (const user of users) {
    Object.assign(user, includes.find(item => item.id === user.id && item.type === 'User'))
    Object.assign(user, user.attributes)
    if (accountant && user.id === JWT.session.uid.toString()) {
      company.accesible = true
    }
  }
}

function mountCompany (company, included) {
  Object.assign(company, company.attributes, company.relationships)
  company.accesible = false
  company.canAssignAccountant = false
  company.messages = []

  includeUsers(company, company.contables.data, included, true)
  includeUsers(company, company.titulares.data, included)
  includeUsers(company, company.other_users.data, included)

  if (JWT.session.superuser) {
    company.accesible = true
  } else {
    if (!company.accesible) {
      company.messages.push('No eres asesor de esta empresa')
      if (company.has_database) {
        company.canAssignAccountant = true
      }
    } else {
      if (company.has_database) {
        company.canRemoveAccountant = true
      }
    }
  }

  if (!company.has_database) {
    company.messages.push('Esta empresa no tiene base de datos creada')
    company.accesible = false
  }

  if (!company.app_facturacion) {
    company.messages.push('Este empresa no tiene acceso a Facturación')
    company.accesible = false
  }

  return company
}

export default Vue.component('CompanySelector', {
  props: {
    cancelable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    log,
    loading: false,
    error: false,
    searchText: '',
    results: []
  }),
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
    window.companySelector = this
  },
  destroyed () {
  },
  methods: {
    setCompany (company) {
      const self = this
      self.loading = true
      JWT.setCompany(company.slug).then(function (session) {
        self.loading = false
        self.$emit('success')
      }).catch(function (error) {
        self.loading = false
        self.error = error
        log.error(error)
      })
    },
    setAccountant (company) {
      const self = this
      self.loading = true
      JWT.setAccountant(company).then(function (response) {
        Object.assign(company, mountCompany(response.data.data, response.data.included))
        self.loading = false
      }).catch(function (error) {
        self.loading = false
        self.error = error
        log.error(error)
      })
    },
    removeAccountant (company) {
      const self = this
      self.loading = true
      JWT.removeAccountant(company).then(function (response) {
        Object.assign(company, mountCompany(response.data.data, response.data.included))
        self.loading = false
      }).catch(function (error) {
        self.loading = false
        self.error = error
        log.error(error)
      })
    },
    search () {
      const self = this

      self.searchDebounce && clearTimeout(self.searchDebounce)
      self.searchDebounce = setTimeout(doSearch, 1000)

      function doSearch () {
        self.loading = true
        self.results = []

        JWT.getCompanies(self.searchText)
          .then(response => {
            if (response.data && Array.isArray(response.data.data)) {
              self.results = response.data.data.map(item => mountCompany(item, response.data.included))
            }
            self.loading = false
          })
          .catch(error => {
            self.loading = false
            self.error = error
            log.error(error)
          })
      }
    },
    cancel () {
      JWT.logout()
    }
  }
})
</script>

<style lang="scss">
</style>
