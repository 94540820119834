'use strict'

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messagesEs from '@/vue/locales/es'
import messagesEn from '@/vue/locales/en'
import axios from 'axios'

Vue.use(VueI18n)

export const fallbackLocale = 'es'
export const messages = { es: messagesEs, en: messagesEn }

export const vuei18n = new VueI18n({
  locale: fallbackLocale,
  fallbackLocale,
  messages
})

window.vuei18n = vuei18n

export const avaliableLanguages = Object.keys(messages)

function setLocale (lang) {
  vuei18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export const changeLanguage = window.changeLanguage = function (lang) {
  if (avaliableLanguages.includes(lang)) {
    return vuei18n.locale !== lang ? setLocale(lang) : false
  } else {
    return false
  }
}

/*
Save from default vue-cli install:

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

*/
