<template>
  <v-app id="loginapp">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex v-if="showLogin && !tenantSelector" xs12 sm8 md4>
            <v-card elevation="18">
              <v-img
                height="120"
                contain
                :src="require('@/assets/logotxerpa_color.png')"
              />
              <v-card-title>Accede a tu oficina</v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="user"
                    prepend-icon="person"
                    name="login"
                    label="Nombre de usuario o email"
                    type="text"
                    @keyup.enter="submit"
                    persistent-placeholder
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    id="password"
                    prepend-icon="lock"
                    name="password"
                    label="Contraseña"
                    type="password"
                    @keyup.enter="submit"
                    persistent-placeholder
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions class="flex-column">
                <v-alert v-for="(error, index) in errors" :key="'error' + index" type="error">
                  {{ error }}
                </v-alert>
                <p class="text-center">
                  <v-btn
                    color= "secondary"
                    :loading="loading"
                    dark
                    large
                    class="ma-1"
                    @click="submit"

                  >Acceder</v-btn>
                </p>
                <p class="text-center"><a :href="changePasswordURL">He olvidado mi usuario o contraseña</a></p>

              </v-card-actions>
              <v-card-text>
                <p class="text-center">
                  <v-btn
                    class="px-6 mt-10"
                    dark
                    color="blue darken-3"
                    :href="facebookURL"
                  >
                    <v-icon left>mdi-facebook</v-icon>
                    Acceder con Facebook
                  </v-btn>
                  <v-btn
                    class="px-6 mt-5 mb-5"
                    dark
                    color="red darken-1"
                    :href="googleURL"
                  >
                    <v-icon left>mdi-google</v-icon>
                    Acceder con Google
                  </v-btn>
                </p>

                <div class="text-right">
                  <a href="https://www.txerpa.com/legal/"> Condiciones de uso</a> |
                  <a href="https://www.txerpa.com/faq/">Ayuda</a> |
                  <a href="https://www.txerpa.com/blog/">Blog Txerpa</a>
                </div>

              </v-card-text>
            </v-card>

          </v-flex>
          <v-flex v-if="tenantSelector">
            <CompanySelector @success="applyTenant" cancelable />
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import JWT from '@/modules/JWT'
import CompanySelector from '@/vue/components/CompanySelector'
import Axios from 'axios'

export default {
  name: 'Login',
  components: {
    CompanySelector
  },
  data: () => ({
    facebookURL: '/login/facebook/?is_registration=false',
    googleURL: '/login/google-oauth2/?is_registration=false',
    loginURL: '/acceder/',
    registrationURL: '/alta/',
    changePasswordURL: '/cambiar/contrasenya/',
    loading: false,
    user: undefined,
    password: undefined,
    errors: [],
    showLogin: false,
    tenantSelector: false
  }),
  mounted () {
    const self = this
    if (JWT.session.token && !JWT.session.tenant) {
      self.tenantSelector = true
    } else {
      self.setSocialLoginUrl()
      self.getErrorMessages()
      self.showLogin = true
    }
  },
  methods: {
    applyTenant () {
      window.envLoader()
    },
    setSocialLoginUrl () {
      const self = this
      const currentQuery = new URLSearchParams(window.location.search)
      if (currentQuery.has('next')) {
        const next = currentQuery.get('next')
        self.facebookURL += '&next=' + next
        self.googleURL += '&next=' + next
      }
    },
    getErrorMessages () {
      const self = this
      const contribMessages = Axios.create({
        baseURL: '/contrib/messages/',
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json'
        }
      })

      contribMessages.get().then(function (response) {
        const errorMessages = []
        for (const message of response.data.messages) {
          errorMessages.push(message.text)
        }
        if (errorMessages) {
          self.errors = errorMessages
        }
      })
    },
    submit () {
      const self = this
      self.errors = []
      self.loading = true
      JWT.login(self.user, self.password).then(function () {
        self.loading = false

        const currentQuery = new URLSearchParams(window.location.search)
        const next = currentQuery.get('next')
        if (next && next !== '/') {
          window.location.assign(next)
        } else {
          if (!JWT.session.tenant) {
            self.tenantSelector = true
          } else {
            window.envLoader()
          }
        }
      }).catch(function (error) {
        const response = error.response
        if (response && response.status === 401) {
          self.errors.push('Usuario o contraseña incorrectas')
        } else {
          if (response && response.data && response.data.error) {
            self.errors.push(response.data.error)
          }
          console.error('Unknow login error', error, response)
        }
        self.loading = false
      })
    },
    redirectToRegistration () {
      const self = this
      const redirectionInfo = location.href.split(self.loginURL)[1]
      if (redirectionInfo) {
        self.registrationURL += redirectionInfo
      }
      location.href = self.registrationURL
    }
  }
}
</script>

<style>
  html {
    font-size: 16px !important;
  }

  #loginapp {
    background-color: #009EFF;
  }
</style>
